.loginPage {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

@media all and (max-width: 600px) {
  .loginPage {
    padding-top: 25px;
  }
}

@media all and (min-width: 601px) {
  .loginPage {
    justify-content: center;
    height: 100vh;
  }
}

.loginPage .logoContainer {
  display: flex;
  justify-content: center;
  margin: 0 0 30px;
}

.loginPage .logoContainer .logo {
  height: auto;
}

@media all and (max-width: 600px) {
  .loginPage .logoContainer .logo {
    width: 85%;
    height: auto;
  }
}

@media all and (min-width: 601px) {
  .loginPage .logoContainer .logo {
    width: 360px;
  }
}

.loginPage .card {
  width: 100%;
}

@media all and (min-width: 601px) {
  .loginPage .card {
    max-width: 450px;
  }
}

@media all and (max-width: 600px) {
  .loginPage .card {
    box-shadow: none !important;
    background: none !important;
  }
}

.loginPage .cardContent {
  padding: 15px 20px;
}

@media all and (max-width: 600px) {
  .loginPage .cardContent {
    padding: 0 10px;
  }
}

.loginPage .formGroup {
  margin-bottom: 25px;
}

.loginPage .separator {
  margin-top: 15px;
  margin-bottom: 10px;
}
