form.form {
  background: #f5f5f5;
  padding: 20px;
}

form.form.form--no-style {
  background: none;
  padding: 0;
}

.bp3-dialog form.form {
  background: none;
  padding: 0 10px;
}
