body.bp3-dark {
  background: #30404d;
}

@media all and (min-width: 601px) {
  .bp3-dialog {
    min-width: 700px;
  }
}

@media all and (max-width: 600px) {
  .bp3-dialog {
    width: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* https://github.com/palantir/blueprint/issues/4347 */
.bp3-control.bp3-checkbox input:checked ~ .bp3-control-indicator::before {
  background-image: url(./svg/checked.svg) !important;
}

/* https://github.com/palantir/blueprint/issues/4347 */
.bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator::before {
  background-image: url(./svg/indeterminate.svg) !important;
}

.bp3-overlay .bp3-dialog-container {
  align-items: flex-start; /* So modals are always displayed at the top of the screen */
  padding-top: 50px; /* So modals are displayed under the topBar */
}

.select-users .bp3-menu {
  max-height: 300px;
  overflow: auto;
}
