.change-request-banner {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  transition: background-color 250ms linear;
}

.change-request-banner--warning {
  background-color: #ffb386;
  font-weight: bold;
}
