.topBar {
  padding: 0 15px;
  height: 50px;
}

.topBar.development {
  background-color: #36a99e;
}

.topBar.test {
  background-color: #144b5c;
}

.topBar.production {
  background-color: #222222;
}

.topBarContent {
  max-width: 1000px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  color: white;
}

.topBarContent .homeLink {
  color: white;
  text-decoration: none;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 50px;
  justify-content: center;
}

.topBarContent .homeLink .logo {
  height: 60%;
}

.topBarContent .homeLink .text {
  padding-left: 10px;
}

@media all and (max-width: 900px) {
  .topBarContent .homeLink .text {
    display: none;
  }
}

.topBarContent .homeLink:hover {
  color: #dedede !important;
}

.topBarContent .navigation {
  display: flex;
  height: 100%;
  align-items: center;
}

.topBarContent .navigation.autoScrollOnOverflowX {
  overflow-x: auto;
}

.topBarContent .navigationLink {
  color: white;
  text-decoration: none;
  font-size: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
}

.topBar.development .topBarContent .navigationLink.active {
  background-color: #22857b;
}

.topBar.test .topBarContent .navigationLink.active {
  background-color: #0c3441;
}

.topBar.production .topBarContent .navigationLink.active {
  background: #3a3a3a;
}

.topBar.development .topBarContent a.navigationLink:hover {
  background: #38bbae;
}

.topBar.test .topBarContent a.navigationLink:hover {
  background: #256d85;
}

.topBar.production .topBarContent a.navigationLink:hover {
  background: #484848;
}

.topBarContent .navigationLink .text {
  padding-left: 6px;
}

@media all and (max-width: 800px) {
  .topBarContent .navigationLink .text {
    display: none;
  }
}

.topBarContent .userDropdown {
  color: white;
  text-decoration: none;
  font-size: 18px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.topBarContent a.userDropdown:hover {
  color: #dedede !important;
}

.profile-menu a {
  color: inherit;
}

.profile-menu a:hover {
  text-decoration: none;
  color: inherit;
}

.topBarContent .userFullName {
  padding-left: 10px;
  padding-right: 10px;

  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.topBarContent .userFullName.compact {
  max-width: 200px;
}

@media all and (max-width: 900px) {
  .topBarContent .userFullName {
    display: none;
  }
}

.topBarContent .fullHeight {
  height: 100%;
}
