body {
  margin: 0;
  padding: 0;
  height: 100vh;
}

.app-root {
  max-height: 100vh;
  height: 100%;
  overflow-y: auto;
  display: grid;
  grid-template-rows: 50px 1fr; /* 50px is for the topBar */
}

.view-content {
  max-width: 100vw;
  width: 100%;
  overflow-x: auto;
  padding: 8px 10px;
}

.view-content .centered-content {
  padding-left: calc(50vw - 500px);
  padding-right: calc(50vw - 500px);
}

.align-center {
  text-align: center;
}

.noMargin {
  margin: 0;
}

.marginTop10px {
  margin-top: 10px;
}

.marginTop20px {
  margin-top: 20px;
}

.marginBottom10px {
  margin-bottom: 10px;
}

.marginBottom20px {
  margin-bottom: 20px;
}

.marginLeft10px {
  margin-left: 10px;
}

.marginLeft15px {
  margin-left: 15px;
}

.marginLeft30px {
  margin-left: 30px;
}

.fontWeight700 {
  font-weight: 700;
}

.blur4 {
  filter: blur(4px);
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer */
  user-select: none;
}
